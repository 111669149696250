<template>
    <div class="zx-prod-bar">
        <div class="zx-prod-title" id="hj">
            <div class="zx-prod-box-1">
                <div class="zx-prod-box-1-left">
                    <h2>慧聚</h2>
                    <div>数据要素服务平台</div>
                    <p>聚合多源异构信源，专注于数据服务全生命周期管理；通过数据API构建数据服务超市，打通数据产品化和数据消费最后一公里，实现数据资产赋能。</p>
                </div>
                <div class="zx-prod-box-1-right">
                    <img src="../../assets/product/prod-title-right-icon.png" alt="">
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar">
            <div class="zx-prod-cont-title">
                <h2>核心功能</h2>
            </div>
            <div class="zx-prod-cont-box">
                <div class="zx-prod-cont-list">
                    <img src="../../assets/product/prod-list-icon-1.png" alt="">
                    <h3>API低代码接入</h3>
                    <p>支持配置化数据服务，快速高效的完成接入</p>
                </div>
                <div class="zx-prod-cont-list">
                    <img src="../../assets/product/prod-list-icon-2.png" alt="">
                    <h3>统一业务管理</h3>
                    <p>提供多场景多业务模式支持，保障可快速的开展业务</p>
                </div>
                <div class="zx-prod-cont-list">
                    <img src="../../assets/product/prod-list-icon-3.png" alt="">
                    <h3>智能服务编排</h3>
                    <p>支持丰富的路由选择策略、流量调度策略，同时可根据入参属性进行智能分配，实现精细化路由管理，精准控制API调用流向</p>
                </div>
                <div class="zx-prod-cont-list">
                    <img src="../../assets/product/prod-list-icon-4.png" alt="">
                    <h3>实时运维监控</h3>
                    <p>支持对API进行调用监控和告警控制，以保障全流程的稳定可靠</p>
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar" style="height: 630px;">
            <div class="zx-prod-cont-title">
                <h2>应用场景</h2>
            </div>
            <div class="zx-prod-scene-box">
                <div class="zx-prod-scene-list">
                    <div class="zx-prod-scene-list-content">
                        <h2>外部数据管理</h2>
                        <p>整合外部数据资源，构建外部数据科学管理机制，在安全、合规的前提下，助企业实现可视化高效接入，同时支持智能路由、数据质量监控、安全预警、计量计价等功能的统一外部数据管理平台</p>
                    </div>
                </div>
                <div class="zx-prod-scene-list">
                    <div class="zx-prod-scene-list-content">
                        <h2>公共信息平台</h2>
                        <p>归集联接行业生产、流通、监管、技术和基础设施等各环节的信息资源，依托数据服务平台，统一面向行业和社会用户开放提供行业公共信息服务，培育行业多元化应用场景
                        </p>
                    </div>
                </div>
                <div class="zx-prod-scene-list">
                    <div class="zx-prod-scene-list-content">
                        <h2>数据交易市场</h2>
                        <p>可实现多源多平台数据资产方引入、统一数据资产上下架管理、打通多消费渠道，让数据资产价值可评估可运营可分析，可主动触达数消费者，让业务侧应用更快速</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar"  style="height: 900px;">
            <div class="zx-prod-cont-title">
                <h2>平台优势</h2>
            </div>
            <div class="zx-advantage-bar">
                <div class="zx-advantage-box">
                    <div class="zx-advantage-list">
                        <img src="../../assets/product/zx-advantage-list-icon-1.png" alt="">
                        <h3>超便捷接入</h3>
                        <p>业务系统、外部应用接入更加方便，通过简单的操作完成数据服务的接入和管理</p>
                    </div>
                    <div class="zx-advantage-list">
                        <img src="../../assets/product/zx-advantage-list-icon-2.png" alt="">
                        <h3>全方位管理</h3>
                        <p>提供数据服务的全生命周期管理能力，帮助企业构建服务治理体系</p>
                    </div>
                    <div class="zx-advantage-list">
                        <img src="../../assets/product/zx-advantage-list-icon-3.png" alt="">
                        <h3>高性能支撑</h3>
                        <p>提供10000+并发量支撑，为企业提供高性能高稳定性的集成中心</p>
                    </div>
                    <div class="zx-advantage-list">
                        <img src="../../assets/product/zx-advantage-list-icon-4.png" alt="">
                        <h3>金融级安全</h3>
                        <p>具备数据全生命周期的安全管控能力，实现数据的可定位、可管控、可审计、可查询，为企业数据安全保驾护航 </p>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "index.vue",
    }
</script>

<style scoped lang="scss">
    .zx-prod-title {
        width: 100%;
        background: #F6F8FA;
        height: 560px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .zx-prod-box-1 {
        display: flex;
        width: 1200px;
        justify-content: space-between;
        text-align: left;
        .zx-prod-box-1-left{
            margin-top: 140px;
        }
        h2{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: normal;
            font-size: 48px;
            color: #121212;
            margin-bottom: 24px;
        }
        div{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            color: #305DC4;
            margin-bottom: 34px;
        }
        p{
            width: 490px;
            height: 105px;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 176.02%;
            text-align: justify;
            color: #121212;
        }
    }

    .zx-prod-cont-bar{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 140px;
        .zx-prod-cont-title{
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #121212;
                position: relative;
                margin-bottom: 118px;
                &:after{
                    content: '';
                    position: absolute;
                    width: 70px;
                    height: 6px;
                    background: #305DC4;
                    border-radius: 11px;
                    left: 50%;
                    margin-left: -35px;
                    top: 90px;
                }
            }
        }
    }
    .zx-prod-cont-box{
        width: 1200px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .zx-prod-cont-list{
            width: 600px;
            height: 266px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #305DC4;
            color: #fff;
            padding-left: 30px;
            box-sizing: border-box;
            img{
                padding-top: 53px;
                margin-bottom: 25px;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 176.02%;
                color: #FFFFFF;
                margin-bottom: 5px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 176.02%;
                letter-spacing: 0.09em;
                color: rgba(255, 255, 255, 0.75);
                text-align: left;
            }
            &:nth-child(2){
                background-color: #2D56B6;
                img{
                    padding-top: 53px;
                }
            }
            &:nth-child(3){
                background-color: #2D56B6;
                img{
                    padding-top: 53px;
                }
            }
            &:nth-child(4){
                background-color: #294DA1;
                img{
                    padding-top: 53px;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(255, 255, 255, 0.1);
                transition: all .5s;
            }
        }
        .zx-prod-ht-gong{
            display: flex;
            width: 100%;
            justify-content: space-between;
            li{
                width: 340px;
                text-align: left;
                position: relative;
                &:nth-child(2):after,&:nth-child(1):after{
                    content: '';
                    width: 1px;
                    height: 63px;
                    background: rgba(255, 255, 255, 0.7);
                    border-radius: 8px;
                    position: absolute;
                    right: -24px;
                    top: 0;
                }
            }
        }
    }

    .zx-prod-cont-hz-box{
        width: 1200px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .zx-prod-cont-hz-list{
            width: 600px;
            height: 346px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #305DC4;
            color: #fff;
            padding-left: 30px;
            box-sizing: border-box;
            width: 400px;
            &:nth-child(4){
                width: 800px;
            }
            img{
                padding-top: 53px;
                margin-bottom: 25px;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 176.02%;
                color: #FFFFFF;
                margin-bottom: 5px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 176.02%;
                letter-spacing: 0.09em;
                color: rgba(255, 255, 255, 0.75);
                width: 329px;
                text-align: left;
            }
            &:nth-child(1){
                background: #305DC4;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(2){
                background: #2D56B6;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(3){
                background: #294DA1;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(4){
                background: #2A54B6;
                img{
                    padding-top: 58px;
                }
            }
            &:nth-child(5){
                background: #305DC4;
                img{
                    padding-top: 58px;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(255, 255, 255, 0.1);
                transition: all .5s;
            }
        }
        .zx-prod-hz-gong{
            display: flex;
            width: 100%;
            justify-content: space-between;
            li{
                text-align: left;
                position: relative;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 176.02%;
                /* or 25px */
                letter-spacing: 0.09em;
                color: rgba(255, 255, 255, 0.75);
                h4{
                    position: relative;
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 176.02%;
                    color: #FFFFFF;
                    margin-bottom: 4px;
                    padding-left: 10px;
                    &::after{
                        content: '';
                        position: absolute;left: 0;top: 8px;
                        width: 3px;
                        height: 15px;
                        background: #FFFFFF;
                        border-radius: 16px;
                    }
                }
                &:nth-child(1){
                    width: 470px;
                    span{
                        display: block;
                        width: 472px;
                    }
                }
                &:nth-child(2){
                    width: 250px;
                    span{
                        display: block;
                        width: 207px;
                    }
                }
                &:nth-child(1):after{
                    content: '';
                    width: 1px;
                    height: 145px;
                    background: rgba(255, 255, 255, 0.7);
                    border-radius: 8px;
                    position: absolute;
                    right: -24px;
                    top: 0;
                }
            }
        }
    }
    .zx-prod-scene-box{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        .zx-prod-scene-list{
            width: 390px;
            height: 306px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            &:nth-child(1){
                background: url("../../assets/product/zx-hj-csent-1.png") no-repeat 100%;
            }
            &:nth-child(2){
                background: url("../../assets/product/zx-hj-csent-2.png") no-repeat 100%;
            }
            &:nth-child(3){
                background: url("../../assets/product/zx-hj-csent-3.png") no-repeat 100%;
            }
            .zx-prod-scene-list-content{
                position: absolute;
                left: 0;
                top: 240px;
                background: rgba(18, 18, 18, 0.55);
                height: 306px;
                transition: all .5s;
            }
            h2{
                height: 66px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 66px;
                color: #FFFFFF;
                margin-bottom: 5px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 1.76;
                color: #fff;
                text-align: left;
                padding: 0 20px;
            }
            &:hover{
                .zx-prod-scene-list-content{
                    top: 0;
                    transition: all .5s;
                }
            }
        }
    }

    .zx-advantage-bar{
        display: flex;
        align-items: center;
    }
    .zx-advantage-box{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        .zx-advantage-list{
            width: 285px;
            height: 420px;
            background: #FFFFFF;
            border: 1px solid rgba(153, 156, 176, 0.12);
            box-shadow: 0px 4px 24px rgba(153, 156, 176, 0.32);
            border-radius: 4px;
            padding-top: 74px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                margin-bottom: 40px;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 176.02%;
                color: #121212;
                margin-bottom: 62px;
            }
            p{
                text-align: left;
                padding: 0 20px;
            }
        }
    }
</style>
